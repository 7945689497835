precision mediump float;

varying vec2 vTextureCoord;

uniform sampler2D uSampler;
uniform sampler2D gradient;

uniform float time;        

void main(void) {

    vec4 maskColor = texture2D(uSampler, vTextureCoord);
    
    vec2 uv;
    uv.x = maskColor.r + (maskColor.b);
    uv.y = vTextureCoord.y;

    vec4 videoColor = texture2D(gradient, uv);
    gl_FragColor = videoColor;

}