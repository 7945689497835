import * as PIXI from 'pixi.js';
import * as Ably from "ably";

import { Heat } from "./lib/heat";
import Utilities from './lib/utilities';

import fragmentShader from './shaders/heat.frag';

interface AnimationOptions {
    duration: number,

    updateCallback?: (number) => any;
    completeCallback?: () => any;

    startTime?: number,
    endTime?: number

    completed?: boolean
}
class Animator {
    static animations: AnimationOptions[] = [];
    static initialized = false;

    static animate(animation: AnimationOptions) {
        if (!Animator.initialized) {
            requestAnimationFrame(Animator.update);
            Animator.initialized = true;
        }

        animation.startTime = performance.now();
        animation.endTime = animation.startTime + animation.duration;

        animation.completed = false;

        Animator.animations.push(animation);
    }

    static update(time) {
        for (const animation of Animator.animations) {

            if (time >= animation.endTime!) {
                animation?.updateCallback?.(1);
                animation?.completeCallback?.();
                animation.completed = true;
            } else {
                let t = (time - animation.startTime!) / (animation.endTime! - animation.startTime!);
                animation?.updateCallback?.(t);
            }
        }

        // Clear expired animations.
        Animator.animations = Animator.animations.filter(animation => !animation.completed);

        requestAnimationFrame(Animator.update);
    }
}

class HeatParticle extends PIXI.Sprite {

    animationWeight: number = 0;

    scaleOffset = Math.random() * 10;
    scaleSpeed = .25 + (Math.random() * .25);

    xOffset = Math.random() * 10;
    yOffset = Math.random() * 10;

    xRange = 64 + Math.random() * 64;
    yRange = 32 + Math.random() * 64;

    xSpeed = .25 + (Math.random() * .25);
    ySpeed = .25 + (Math.random() * .25);

    sourcePosition: PIXI.IPoint;
    startTime: number;

    constructor(texture, x: number, y: number) {
        super(texture);

        this.anchor.set(0.5);
        this.alpha = .5;
        this.tint = 0x000080;

        this.position.set(x, y);
        this.sourcePosition = new PIXI.Point(x, y);

        this.startTime = performance.now();

        Animator.animate({
            duration: 1500,
            updateCallback: (t) => { this.animationWeight = t }
        });
    }

    destroy() {
        let startAlpha = this.alpha;
        Animator.animate({
            duration: 500,
            updateCallback: (t) => { this.alpha = (1 - t) * startAlpha; },
            completeCallback: () => { this.parent.removeChild(this); }
        });
    }

    update(time, deltaTime) {
        let scale = Math.abs(Math.sin((time / 1000 * this.scaleSpeed) + this.scaleOffset)) * (1 - .5) + .5;
        this.scale.set(scale);

        let newX = this.sourcePosition.x + (Math.sin((time / 1000 * this.xSpeed) + this.xOffset) * this.xRange);
        let newY = this.sourcePosition.y + (Math.sin((time / 1000 * this.ySpeed) + this.yOffset) * this.yRange);

        let t = this.animationWeight;
        newX = (1 - t) * this.sourcePosition.x + t * newX;
        newY = (1 - t) * this.sourcePosition.y + t * newY;

        this.position.set(newX, newY);
    }
}

class Tallyoop {
    active = false;
    maxParticles = 5000;

    particleUrl = new URL("sprites/particle.png", import.meta.url);
    gradientUrl = new URL("textures/gradient.png", import.meta.url);

    app: PIXI.Application;
    sprites: PIXI.ParticleContainer;
    renderTexture: PIXI.RenderTexture;

    particles: HeatParticle[] = [];
    lastParticleCount = 0;

    dataset: number[][] = [];
    clusterWorker: Worker;
    clustering: boolean = false;

    heatChannel: string = Utilities.getChannel();
    heatClicks = 0;

    gatewayTimestamp: number | null = null;

    constructor() {
        // Main container.
        const main: HTMLElement = document.getElementById('main')!;

        // Initialize Heat.
        {
            const heat = new Heat(this.heatChannel);

            heat.addEventListener('open', async () => {
                console.log("Clearing timestamp.");
                this.gatewayTimestamp = null;
            });

            heat.addEventListener('click', (e) => {
                // console.log(e);
                if (this.active) {
                    this.drawClick(e.detail.x, e.detail.y);
                    this.heatClicks++;
                }
            });

            // Watch gateway.
            const checkGateway = async () => {
                try {
                    let result = await fetch('https://heat-api.j38.net/gateway');
                    let data = await result.json();

                    if (this.gatewayTimestamp == null) {
                        console.log("Caching gateway timestamp.");
                        this.gatewayTimestamp = data.timestamp;
                    } else if (this.gatewayTimestamp != data.timestamp) {
                        console.log("Timestamp mismatch!");
                        heat.ws?.close();
                    }
                } catch (e) {
                    console.log(e.message);
                }

                setTimeout(() => {
                    checkGateway();
                }, 5000);
            }
            checkGateway();
        }

        // Pixi setup.
        this.app = new PIXI.Application({
            resizeTo: window,
            resolution: devicePixelRatio,
            autoDensity: true,
            backgroundAlpha: 0,
        });
        main.appendChild(this.app.view as HTMLCanvasElement);

        // Gradient texture setup.
        const gradientTexture = PIXI.Texture.from(this.gradientUrl.toString());

        // Render texture setup.
        const width = this.app.screen.width;
        const height = this.app.screen.height;
        const baseRenderTexture = new PIXI.BaseRenderTexture({ width, height });
        this.renderTexture = new PIXI.RenderTexture(baseRenderTexture);
        const renderTextureSprite = new PIXI.Sprite(this.renderTexture);
        this.app.stage.addChild(renderTextureSprite)

        // Shader setup.
        const simpleShader = new PIXI.Filter(undefined, fragmentShader);
        simpleShader.uniforms.gradient = gradientTexture;
        renderTextureSprite.filters = [simpleShader]

        // Particle container.
        this.sprites = new PIXI.ParticleContainer(this.maxParticles, {
            position: true,
            scale: true,
            tint: true
        });
        this.sprites.blendMode = PIXI.BLEND_MODES.SCREEN;
        // this.app.stage.addChild(this.sprites);

        // Listen for animate update
        this.app.ticker.add((delta) => {
            for (let particles of this.particles) {
                particles.update(performance.now(), delta);
            }

            // Render to texture.
            this.renderTexture.baseTexture.clearColor = [0, 0, 0, 1];
            this.app.renderer.render(this.sprites, { renderTexture: this.renderTexture });
        });

        // Ably Setup
        (async () => {

            // For the full code sample see here: https://github.com/ably/quickstart-js
            const ably = new Ably.Realtime.Promise(Utilities.getKey());
            await ably.connection.once('connected');
            console.log('Connected to Ably!');

            // get the channel to subscribe to
            const ablyChannel = ably.channels.get(this.heatChannel);
            ablyChannel.st

            /* 
              Subscribe to a channel
              The promise resolves when the channel is attached 
              (and resolves synchronously if the channel is already attached).
                */
            await ablyChannel.subscribe('command', async (message) => {
                console.log('Received a command message in realtime: ' + message.data)
                if (message.data == "start") this.active = true;
                if (message.data == "pause") this.active = false;
                if (message.data == "clear") this.clearClicks();

                await sendStatus();
            });

            await ablyChannel.subscribe('heat', (message) => {
                console.log('Received a heat message in realtime: ' + JSON.stringify(message.data))
                this.drawClick(message.data.x, message.data.y);
            });

            // Send status.

            const sendStatus = async () => {
                await ablyChannel.publish("status", {
                    active: (this.active) ? "Started" : "Paused",
                    clicks: (this.heatClicks)
                })
            }

            window.setInterval(async () => {
                sendStatus();
            }, 5000);

        })();


        // Clustering
        let dataset = [];

        setInterval(() => {
            if (this.lastParticleCount != this.particles.length && !this.clustering) {
                this.clustering = true;
                this.clusterWorker.postMessage(this.dataset);
                this.lastParticleCount = this.particles.length;
            }
        }, 1000);

        this.clusterWorker = new Worker(
            new URL('./lib/worker.ts', import.meta.url),
            { type: 'module' }
        );

        this.clusterWorker.onmessage = (e) => {
            let clusters = e.data;


            // TODO(SJG): Workers!
            for (let i = 0; i < clusters.length; i++) {
                const cluster = clusters[i];
                let tint = (i == 0) ? 0X808080 : 0x000080;
                for (let index of cluster) {
                    const particle: HeatParticle = this.particles[index];
                    particle.tint = tint;
                }
            }

            this.clustering = false;
        }

        // Handle clicks.
        window.addEventListener("click", (e) => {
            const normalizedX = e.offsetX / this.app.screen.width;
            const normalizedY = e.offsetY / this.app.screen.height;
            this.drawClick(normalizedX, normalizedY, true);
        })

        // Handle window resize.
        window.addEventListener('resize', () => {
            renderTextureSprite.width = this.app.screen.width;
            renderTextureSprite.height = this.app.screen.height;
            this.renderTexture.resize(this.app.screen.width, this.app.screen.height, true);
        });

        // this.testClick();

    }


    drawClick(clickX: number, clickY: number, force: boolean = false) {
        if (!this.active && !force) return;

        if (this.sprites.children.length >= this.maxParticles) return;

        const screenX = clickX * this.app.screen.width;
        const screenY = clickY * this.app.screen.height;

        const texture = PIXI.Texture.from(this.particleUrl.toString());
        const particle: HeatParticle = new HeatParticle(texture, screenX, screenY);

        this.sprites.addChild(particle);
        this.particles.push(particle);

        this.dataset.push([clickX, clickY]);

        // console.log("Click count: " + this.particles.length);
    }

    clearClicks() {
        while (this.particles.length) {
            let particle = this.particles.shift();
            particle?.destroy();
        }
        this.dataset = [];
    }

    testClick() {
        this.drawClick(Math.random(), Math.random());
        setTimeout(() => { this.testClick(); }, 250);
    }


}

const tallyoop = new Tallyoop();
window.tallyoop = tallyoop;
export default tallyoop;